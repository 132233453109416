import OutputUpload from "../components/input/DataUpload";
import "./InputPage.css";

function InputPage() {

  return (
      <OutputUpload />
  );
}

export default InputPage;
